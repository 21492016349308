import { Action } from 'redux'

import { AdminUserData } from '../../../requests/user/user.domain'

export const ADMIN_FETCH_USER_START = 'ADMIN/USER_MANAGEMENT/FETCH_USER_START'
export const ADMIN_FETCH_USER_IN_PROGRESS = 'ADMIN/USER_MANAGEMENT/FETCH_USER_IN_PROGRESS'
export const ADMIN_FETCH_USER_DONE = 'ADMIN/USER_MANAGEMENT/FETCH_USER_DONE'
export const ADMIN_FETCH_USER_ERROR = 'ADMIN/USER_MANAGEMENT/FETCH_USER_ERROR'
export const ADMIN_CLEAN_USER = 'ADMIN/USER_MANAGEMENT/CLEAN_USER'

export const ADMIN_PATCH_USER_START = 'ADMIN/USER_MANAGEMENT/PATCH_USER_START'
export const ADMIN_PATCH_USER_IN_PROGRESS = 'ADMIN/USER_MANAGEMENT/PATCH_USER_IN_PROGRESS'
export const ADMIN_PATCH_USER_DONE = 'ADMIN/USER_MANAGEMENT/PATCH_USER_DONE'
export const ADMIN_PATCH_USER_ERROR = 'ADMIN/USER_MANAGEMENT/PATCH_USER_ERROR'

export const ADMIN_DELETE_USER_START = 'ADMIN/USER_MANAGEMENT/DELETE_USER_START'
export const ADMIN_DELETE_USER_IN_PROGRESS = 'ADMIN/USER_MANAGEMENT/DELETE_USER_IN_PROGRESS'
export const ADMIN_DELETE_USER_DONE = 'ADMIN/USER_MANAGEMENT/DELETE_USER_DONE'
export const ADMIN_DELETE_USER_ERROR = 'ADMIN/USER_MANAGEMENT/DELETE_USER_ERROR'

export interface IAdminFetchUserStartAction extends Action {
  type: typeof ADMIN_FETCH_USER_START
  username: string
}
export const fetchUserActionStart = (username: string): IAdminFetchUserStartAction => {
  return {
    type: ADMIN_FETCH_USER_START,
    username,
  }
}

export interface IAdminFetchUserInProgressAction extends Action {
  type: typeof ADMIN_FETCH_USER_IN_PROGRESS
}
export const fetchUserActionInProgress = (): IAdminFetchUserInProgressAction => {
  return {
    type: ADMIN_FETCH_USER_IN_PROGRESS,
  }
}

export interface IAdminFetchUserDoneAction extends Action {
  type: typeof ADMIN_FETCH_USER_DONE
  user: AdminUserData
}
export const fetchUserActionDone = (user: AdminUserData): IAdminFetchUserDoneAction => {
  return {
    type: ADMIN_FETCH_USER_DONE,
    user,
  }
}

export interface IAdminFetchUserErrorAction extends Action {
  type: typeof ADMIN_FETCH_USER_ERROR
  error: Error
}
export const fetchUserActionError = (error: Error): IAdminFetchUserErrorAction => {
  return {
    type: ADMIN_FETCH_USER_ERROR,
    error,
  }
}

export interface IAdminCleanUserAction extends Action {
  type: typeof ADMIN_CLEAN_USER
}
export const cleanUserAction = (): IAdminCleanUserAction => {
  return {
    type: ADMIN_CLEAN_USER,
  }
}

export interface IAdminUserPatchStartAction extends Action {
  type: typeof ADMIN_PATCH_USER_START
  userData: AdminUserData
}

export const patchUserActionStart = (userData: AdminUserData): IAdminUserPatchStartAction => {
  return {
    type: ADMIN_PATCH_USER_START,
    userData,
  }
}

export interface IAdminUserPatchInProgressAction extends Action {
  type: typeof ADMIN_PATCH_USER_IN_PROGRESS
}
export const patchUserActionInProgress = (): IAdminUserPatchInProgressAction => {
  return {
    type: ADMIN_PATCH_USER_IN_PROGRESS,
  }
}

export interface IAdminUserPatchDoneAction extends Action {
  type: typeof ADMIN_PATCH_USER_DONE
  userData: AdminUserData
}
export const patchUserActionDone = (user: AdminUserData): IAdminUserPatchDoneAction => {
  return {
    type: ADMIN_PATCH_USER_DONE,
    userData: user,
  }
}

export interface IAdminUserPatchErrorAction extends Action {
  type: typeof ADMIN_PATCH_USER_ERROR
  error: Error
}
export const patchUserActionError = (error: Error): IAdminUserPatchErrorAction => {
  return {
    type: ADMIN_PATCH_USER_ERROR,
    error,
  }
}

export interface IAdminUserDeleteStartAction extends Action {
  type: typeof ADMIN_DELETE_USER_START
  username: string
}
export const deleteUserActionStart = (username: string): IAdminUserDeleteStartAction => {
  return {
    type: ADMIN_DELETE_USER_START,
    username,
  }
}

export interface IAdminUserDeleteInProgressAction extends Action {
  type: typeof ADMIN_DELETE_USER_IN_PROGRESS
}
export const deleteUserActionInProgress = (): IAdminUserDeleteInProgressAction => {
  return {
    type: ADMIN_DELETE_USER_IN_PROGRESS,
  }
}

export interface IAdminUserDeleteDoneAction extends Action {
  type: typeof ADMIN_DELETE_USER_DONE
  username: string
}
export const deleteUserActionDone = (username: string): IAdminUserDeleteDoneAction => {
  return {
    type: ADMIN_DELETE_USER_DONE,
    username,
  }
}

export interface IAdminUserDeleteErrorAction extends Action {
  type: typeof ADMIN_DELETE_USER_ERROR
  error: Error
}
export const deleteUserActionError = (error: Error): IAdminUserDeleteErrorAction => {
  return {
    type: ADMIN_DELETE_USER_ERROR,
    error,
  }
}

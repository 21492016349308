import React, { useCallback, useEffect, useMemo, useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import DataTable, { TableColumn } from 'react-data-table-component'
import { useForm } from 'react-hook-form'

import { FilterUserComponent } from './FilterUserComponent'

import { AdminUserData } from '../../../requests/user/user.domain'
import { RouteLink } from '../../common'
import { customStylesForUserTable } from '../../../utils/customStyles'
import { deleteUserActionStart } from '../../../admin/user-management/user/adminUser.actions'
import { resetUserPasswordActionStart } from '../../../admin/user-management/reset-password/resetPassword.actions'
import { useAppDispatch } from '../../../utils/hooks'
import { Button } from '../../common/button/Button'
interface ITableData {
  addressLine1: string
  addressLine2: string
  city: string
  country: string
  email: string
  fullName: string
  phone: string
  postcode: string
  rmaCustomerId: string
  role: string
  userId: string
  username: string
}
import { Dialog } from '../../common/dialog/Dialog'
import { Spinner } from '../../common/spinner/Spinner'
import './styles.scss'
import { BACKEND_URL } from '../../../config'
import { noContentTypeHeaders } from '../../../utils/apiHeaders'
import { ErrorBox } from '../../common/errorBox/ErrorBox'
import { SuccessBox } from '../../common/successBox/SuccessBox'
import { getPriceListTypeList } from '../../../requests/price-list'

type AdminUserListItemProps = {
  index?: number
  setShowSuccessMessage: React.Dispatch<React.SetStateAction<boolean>>
  setUsernameForResetPassword: React.Dispatch<React.SetStateAction<string>>
  tableData: AdminUserData[]
}

interface IPriceListResponse {
  [key: string]: string
}

export const AdminUserListItem = ({
  tableData,
  setShowSuccessMessage,
  setUsernameForResetPassword,
}: AdminUserListItemProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [filterText, setFilterText] = useState('')
  const [filteredItems, setFilteredItems] = useState<AdminUserData[]>([])
  const { register, handleSubmit } = useForm()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [sendingForm, setSendingForm] = useState(false)
  const [b2bPriceList, setB2bPriceList] = useState<FileList | null>(null)
  const [b2cPriceList, setB2cPriceList] = useState<FileList | null>(null)
  const [servicePriceList, setServicePriceList] = useState<FileList | null>(null)
  const [showErrorMessage, setShowErrorMessage] = useState<boolean | string>(false)
  const [showErrorLackOfCustomerRmaID, setShowErrorLackOfCustomerRmaID] = useState<
    boolean | string
  >(false)
  const [sentUploadFile, setSentUploadFile] = useState<boolean | null>(false)
  const [rmaCustomerIdValue, setRmaCustomerIdValue] = useState<string>('')
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [b2bFileName, setB2bFileName] = useState('')
  const [b2cFileName, setB2cFileName] = useState('')
  const [serviceFileName, setServiceFileName] = useState('')
  const [counter, setCounter] = useState<number>(0)
  const [errorFetchPriceList, setErrorFetchPriceList] = useState<boolean | string>(false)

  const handleOpenDialog = useCallback((customerId: string) => {
    setRmaCustomerIdValue(customerId)
    setTimeout(() => {
      setIsOpen(true)
    }, 500)
  }, [])

  const goToRepairRequest = (username: string) => {
    navigate(RouteLink.ADMIN_USERS + `/${username}`)
  }

  const handleFilterText = useCallback(() => {
    setFilteredItems(() =>
      tableData.filter(
        (item: AdminUserData) =>
          JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
      )
    )
  }, [tableData, filterText])

  const deleteUser = (username: string) => {
    const text = t('common.confirmDeletePassword', { userName: username })
    const answer = window.confirm(text)
    answer && dispatch(deleteUserActionStart(username))
  }

  const resetUserPassword = (username: string) => {
    setUsernameForResetPassword(username)
    const text = t('common.confirmResetPassword', { userName: username })
    const answer = window.confirm(text)
    answer && dispatch(resetUserPasswordActionStart(username))
    answer && setShowSuccessMessage(true)
  }

  const fieldPriceListValidator = {
    maxFiles: (value: FileList) => !(value.length > 1),
    maxFileSize: (value: FileList) => !Array.from(value).some((file) => file.size > 20_000_000),
  }

  const handlePriceListUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const inputElement = e.target as HTMLInputElement
    const files: FileList | null = inputElement.files
    if (e.target.name === 'b2bPriceList') {
      setB2bPriceList(files)
    } else if (e.target.name === 'b2cPriceList') {
      setB2cPriceList(files)
    } else if (e.target.name === 'servicePriceList') {
      setServicePriceList(files)
    }
  }

  const sendUploadFile = (priceListType: string, data: FileList) => {
    if (!rmaCustomerIdValue) {
      setShowErrorLackOfCustomerRmaID(true)
      return
    }
    setSendingForm(true)
    const URL_API_UPLOAD_FILE_REQUEST =
      BACKEND_URL + `/api/price-list/${rmaCustomerIdValue}/upload/${priceListType}`
    const formData = new FormData()
    formData.append('priceListFile', data[0])

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: noContentTypeHeaders(),
      body: formData,
    }

    fetch(URL_API_UPLOAD_FILE_REQUEST, requestOptions)
      .then((response) => {
        if (response.ok) {
          setSentUploadFile(true)
          setCounter((prevCounter) => prevCounter + 1)
        }
        if (!response.ok) {
          setSentUploadFile(false)
          if (response.status === 403 || response.status === 401) {
            throw new Error('Unauthorized')
          }
          setShowErrorMessage(true)
        }
      })
      .catch((err) => {
        setShowErrorMessage(true)
        throw new Error(err.message)
      })
      .finally(() => setSendingForm(false))
  }

  const onSubmit = () => {
    if (b2bPriceList) {
      sendUploadFile('B2B', b2bPriceList)
    }

    if (b2cPriceList) {
      sendUploadFile('B2C', b2cPriceList)
    }

    if (servicePriceList) {
      sendUploadFile('SERVICE_PRICE_LIST', servicePriceList)
    }
  }

  const setPriceListNames = (priceListTypes: IPriceListResponse) => {
    if ('B2B' in priceListTypes) {
      setB2bFileName(priceListTypes['B2B'])
    }
    if ('B2C' in priceListTypes) {
      setB2cFileName(priceListTypes['B2C'])
    }
    if ('SERVICE_PRICE_LIST' in priceListTypes) {
      setServiceFileName(priceListTypes['SERVICE_PRICE_LIST'])
    }
  }

  const handlePriceListInfo = useCallback(async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const priceListTypes: any = await getPriceListTypeList(rmaCustomerIdValue)
      setPriceListNames(priceListTypes)
      setLoadingData(false)
    } catch {
      setLoadingData(false)
      setErrorFetchPriceList(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rmaCustomerIdValue, isOpen])

  useEffect(() => {
    if (rmaCustomerIdValue) {
      setLoadingData(true)
      handlePriceListInfo()
    } else {
      setLoadingData(false)
    }
  }, [rmaCustomerIdValue, handlePriceListInfo, isOpen, counter])

  useEffect(() => {
    setShowErrorMessage('')
    setErrorFetchPriceList('')
    setB2bFileName('')
    setB2cFileName('')
    setServiceFileName('')
    setSentUploadFile(false)
  }, [isOpen])

  const columnsTable: TableColumn<ITableData>[] = [
    {
      name: 'Ganzer namen',
      selector: (row) => row.fullName,
      sortable: true,
      width: '14%',
    },
    {
      name: 'Login',
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Customer RmaId',
      selector: (row) => row.rmaCustomerId,
      width: '10%',
    },
    {
      name: 'Rolle',
      selector: (row) => row.role,
      width: '6%',
      sortable: true,
    },
    {
      name: '',
      width: '33%',
      cell: (row: ITableData) => (
        <div>
          {row.username && (
            <>
              <Button
                className="button button-primary"
                onClick={() => handleOpenDialog(row.rmaCustomerId)}
                style={{ padding: '0.5rem' }}
              >
                Upload price list
              </Button>
              <Button
                className="button button-primary ml-0.5"
                onClick={() => goToRepairRequest(row.username)}
                style={{ padding: '0.5rem' }}
              >
                Edit
              </Button>
              <Button
                className="button button-danger ml-0.5"
                onClick={() => deleteUser(row.username)}
                style={{ padding: '0.5rem' }}
              >
                Delete
              </Button>
              <Button
                className="button button-danger ml-0.5"
                onClick={() => resetUserPassword(row.username)}
                style={{ padding: '0.5rem' }}
              >
                Reset Password
              </Button>
            </>
          )}
        </div>
      ),
    },
  ]

  const subHeaderComponent = useMemo(() => {
    return (
      <FilterUserComponent
        onFilter={(e: ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
        filterText={filterText}
        handleFilterText={handleFilterText}
      />
    )
  }, [filterText, handleFilterText])

  return (
    <>
      <div className="grid bg-white hover:bg-slate-100">
        <DataTable
          columns={columnsTable}
          noHeader
          data={(filterText ? filteredItems : tableData) as ITableData[]}
          customStyles={customStylesForUserTable}
          subHeader
          subHeaderComponent={subHeaderComponent}
        />
      </div>
      <div>
        {isOpen ? (
          <Dialog
            setIsOpen={setIsOpen}
            title={t('priceList.dialogTitle')}
            rmaCustomerId={rmaCustomerIdValue || ''}
            setRmaCustomerIdValue={setRmaCustomerIdValue}
          >
            {loadingData ? <Spinner text={t('common.loadingData')} /> : <></>}
            {sendingForm ? <Spinner text={t('menu.contactForm.sendingData')} /> : <></>}
            <form
              className="price-list-form form"
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
            >
              <div className="form-group mb-4 text-gray-15">
                <label htmlFor="b2bPriceList">{`${t('priceList.B2B')}`}</label>
                <input
                  className="form-control bg-white mt-4"
                  ref={register({ validate: fieldPriceListValidator })}
                  type="file"
                  name="b2bPriceList"
                  accept=".pdf"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handlePriceListUpload(e)}
                />
                {b2bFileName ? (
                  <div className="font-bold text-blue-700 mt-2">
                    {t('priceList.existFileNameUploaded', { priceListName: b2bFileName })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group mb-4 text-gray-15">
                <label htmlFor="b2bPriceList">{`${t('priceList.B2C')}`}</label>
                <input
                  className="form-control bg-white mt-4"
                  ref={register({ validate: fieldPriceListValidator })}
                  type="file"
                  name="b2cPriceList"
                  accept=".pdf"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handlePriceListUpload(e)}
                />
                {b2cFileName ? (
                  <div className="font-bold text-blue-700 mt-2">
                    {t('priceList.existFileNameUploaded', { priceListName: b2cFileName })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group text-gray-15">
                <label htmlFor="servicePriceList">{`${t('priceList.SERVICE_PRICE_LIST')}`}</label>
                <input
                  className="form-control bg-white mt-4"
                  ref={register({ validate: fieldPriceListValidator })}
                  type="file"
                  name="servicePriceList"
                  accept=".pdf"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handlePriceListUpload(e)}
                />
                {serviceFileName ? (
                  <div className="font-bold text-blue-700 mt-2">
                    {t('priceList.existFileNameUploaded', { priceListName: serviceFileName })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group mt-8 mb-8">
                <div className="text-right">
                  <Button
                    disabled={sendingForm || (!b2bPriceList && !b2cPriceList && !servicePriceList)}
                    className={
                      sendingForm || (!b2bPriceList && !b2cPriceList && !servicePriceList)
                        ? 'opacity-50 cursor-not-allowed accent-gray-5'
                        : ''
                    }
                    type="submit"
                  >
                    {t('priceList.sendPriceList')}
                  </Button>
                </div>
              </div>
            </form>
            {showErrorLackOfCustomerRmaID ? (
              <ErrorBox
                title={t('priceList.error')}
                text={`${t('priceList.errorLackOfCustomerRmaID')}`}
                // onClick={() => setShowErrorLackOfCustomerRmaID(false)}
              />
            ) : (
              <></>
            )}
            {errorFetchPriceList ? (
              <ErrorBox
                title={t('priceList.error')}
                text={`${t('priceList.errorFetchPriceList')}`}
                onClick={() => setShowErrorMessage(false)}
              />
            ) : (
              <></>
            )}
            {showErrorMessage ? (
              <ErrorBox
                title={t('priceList.error')}
                text={`${t('priceList.errorMessage')}`}
                onClick={() => setShowErrorMessage(false)}
              />
            ) : (
              <></>
            )}
            {sentUploadFile ? (
              <SuccessBox
                text={` ${t('priceList.formSentSuccessfully')}`}
                onClick={() => setSentUploadFile(false)}
              />
            ) : (
              <></>
            )}
          </Dialog>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
